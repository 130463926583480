import React from 'react'

const CallToAction = () => {
    return (
        <section className='cta'>
                <a className='cta__instagram' href="https://www.instagram.com/feeabreu2/" target="_blanket">
                    @feeabreu2 
                </a>
                <a className='cta__whatsapp' href="https://wa.me/5548984274138" target="_blanket">
                    Whatsapp 
                </a>
        </section>
        )
    }
    
export default CallToAction;
    