import React from 'react'

const Contact = () => {
    return (
        <section id="contact" className='section section--dark'>
            <div className="section__title">
                <h2>Quero fazer uma tatuagem!</h2>
                <p>- Tudo o que você precisa saber para <b>começar o seu projeto</b> -</p>
            </div>
            <div className='contact__container'>
                <div>
                    <h4>Envie uma mensagem seguindo as orientações abaixo para iniciar seu projeto de <b>tatuagem:</b></h4>
                    <ul>
                        <li>Referência(s) da ideia;</li>
                        <li>Local do corpo onde deseja fazer a tatuagem;</li>
                        <li>Tamanho aproximado em cm.</li>
                    </ul>

                    <p>Ou se perferir, entre em <a href="https://wa.me/5548984274138" target="_blanket">contato</a> para auxilio no processo da idealização.</p>
                </div>
                <div className='contact__btn'>
                    <a href="https://wa.me/5548984274138" target="_blanket">
                        Entrar em contato
                    </a>
                </div>
            </div>
        </section>
    )
}

export default Contact;
