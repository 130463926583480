import React from "react";
import Slider from "react-slick";
import carousel4 from '../img/carousel-4-min.jpg'
import carousel5 from '../img/carousel-5-min.JPG'


export default function Carousel() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
  };
  return (
    <div className="carousel">
    <div className="carousel__text">
      <p>AGENDA ABERTA</p>
      <h1>Tatuagem - Cobertura - Reforma</h1>
      <h2>Ingleses, Florianópolis - SC</h2>
      <a className="carousel__btn" href="https://wa.me/5548984274138" target="_blanket">Fazer orçamento</a>
    </div>
    <Slider {...settings}>
      <div className="carousel__item">
        <img className="carousel__img" alt="banner" src={carousel4}></img>
      </div>
      <div className="carousel__item">
        <img className="carousel__img" alt="banner" src={carousel5}></img>
      </div>
    </Slider>
    </div>
  );
}