import './App.css';
import Header from './components/header';
import Carousel from './components/carousel';
import CallToAction from './components/callToAction';
import Gallery from './components/gallery';
import Contact from './components/contact';
import Footer from './components/footer';
import './components/styles.scss'
import About from './components/about';
// import Map from './components/map';

function App() {
  return (
    <div className="App">
      <Header />
      <Carousel />
      <About />
      <CallToAction />
      <Gallery />
      <Contact /> 
      <Footer /> 
      {/* {/* <Map /> */}
      
    </div>
  );
}

export default App;
