import React from 'react'
import logo from '../img/logo.png'

const Header = () => {
    return (
        <header className='header'>
            <div className='header__logo'>
                <img alt="Fee Abreu Tattoo" src={logo}></img>
            </div>
            <nav>
                <ul className='header__list'>
                    <li><a href="#sobre">Sobre</a></li>
                    <li><a href="#gallery">Galeria de Tatuagem</a></li>
                    <li><a href="#contact">Orçamento e Contato</a></li>
                    {/* <li><a href="localizaçao">Localização</a></li> */}
                </ul>
            </nav>
        </header>
    )
}

export default Header;
