import React from "react";
import Slider from "react-slick";

import gallery1 from '../img/gallery-1-min.JPG'
import gallery2 from '../img/gallery-2-min.JPG'
import gallery3 from '../img/gallery-3-min.JPG'
import gallery4 from '../img/gallery-4-min.JPG'
import gallery5 from '../img/gallery-5-min.jpg'
import gallery6 from '../img/gallery-6-min.jpg'
import gallery7 from '../img/gallery-7-min.JPG'
import gallery9 from '../img/gallery-9-min.jpeg'
import gallery10 from '../img/gallery-10-min.jpeg'
import gallery11 from '../img/gallery-11-min.jpeg'
import gallery12 from '../img/gallery-12-min.jpeg'
import gallery13 from '../img/gallery-13-min.jpeg'
import gallery14 from '../img/gallery-14-min.jpeg'
import gallery16 from '../img/gallery-16-min.jpeg'
import gallery18 from '../img/gallery-18.jpeg'
import gallery19 from '../img/gallery-19.jpeg'

export default function Gallery() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay:true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
    ]
  };
  return (
    <section id="gallery">
      <div className="gallery__title section__title">
        <h2>Galeria de Tatuagem</h2>
        <p>- Trabalhos Autorais -</p>
      </div>
      <Slider {...settings}>
        <div className="gallery__item">
          <img src={gallery1} alt="gallery"></img>
        </div>
        <div className="gallery__item">
          <img src={gallery2} alt="gallery"></img>
        </div>
        <div className="gallery__item">
          <img src={gallery3} alt="gallery"></img>
        </div>
        <div className="gallery__item">
          <img src={gallery4} alt="gallery"></img>
        </div>
        <div className="gallery__item">
          <img src={gallery5} alt="gallery"></img>
        </div>
        <div className="gallery__item">
          <img src={gallery6} alt="gallery"></img>
        </div>
        <div className="gallery__item">
          <img src={gallery7} alt="gallery"></img>
        </div>
        <div className="gallery__item">
          <img src={gallery9} alt="gallery"></img>
        </div>
        <div className="gallery__item">
          <img src={gallery10} alt="gallery"></img>
        </div>
        <div className="gallery__item">
          <img src={gallery11} alt="gallery"></img>
        </div>
        <div className="gallery__item">
          <img src={gallery12} alt="gallery"></img>
        </div>
        <div className="gallery__item">
          <img src={gallery13} alt="gallery"></img>
        </div>
        <div className="gallery__item">
          <img src={gallery14} alt="gallery"></img>
        </div>
        <div className="gallery__item">
          <img src={gallery16} alt="gallery"></img>
        </div>
        <div className="gallery__item">
          <img src={gallery18} alt="gallery"></img>
        </div>
        <div className="gallery__item">
          <img src={gallery19} alt="gallery"></img>
        </div>
      
      </Slider>
    </section>
  );
}