import React from 'react'
import logo from '../img/logo.png'


const About = () => {
    return (
        <section className='about' id='sobre'>
            <div>
                <img alt="Fee Abreu Tattoo" src={logo}></img>
            </div>
            <div>
            <p>Me chamo Fernando e sou tatuador e desenhista com mais de dez anos de experiência. 
                Nesse tempo trabalhei com vários estilos, o que me deu ampla noção de criação e oportunidade de trabalhar preferencialmente com desenhos autorais. Posso dizer que me inspiro muito em blackwork, old school, new school, asbtrato e oriental. Faço desde fechamentos, reformas, coberturas a tatuagens delicadas, sempre procurando a originalidade de cada trabalho.</p>

            <p>Os atendimentos são feitos em um estúdio privado localizado nos Ingleses do Rio Vermelho, próximo a Rua do Futuro e Rua das Gaivotas.</p>
            </div>
        </section>
    )
}

export default About;
